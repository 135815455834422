import React from "react";
import { graphql } from "gatsby";

import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/index.css";

import { renderBlock } from "../helpers/renderBlock";

import { HelmetBlock } from "../components/HelmetBlock";
import { Header } from "../components/HeaderBlock";
import { Footer } from "../components/FooterBlock";
import { CookieBanner } from "../components/CookieBanner";

const IndexPage = ({ data }) => {
  const components = data.wpPage.components.component;
  const pageId = data.wpPage.databaseId;

  return (
    <>
      <HelmetBlock data={data.wpPage} />
      <Header data={data.wpPage} />
      {components &&
        components.map((item, i) => (
          <div key={`${item.fieldGroupName}_${i}`}>
            {renderBlock(item, pageId)}
          </div>
        ))}
      <Footer />
      <CookieBanner />
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      databaseId
      parentDatabaseId
      seo {
        title
        metaDesc
      }
      components {
        component {
          ... on WpPage_Components_Component_Main {
            fieldGroupName
            ...MainBlock
          }
          ... on WpPage_Components_Component_HowTo {
            fieldGroupName
            ...HowToBlock
          }
          ... on WpPage_Components_Component_Reviews {
            fieldGroupName
            ...ReviewsBlock
          }
          ... on WpPage_Components_Component_Faq {
            fieldGroupName
            ...FaqBlock
          }
          ... on WpPage_Components_Component_Info {
            fieldGroupName
            ...InfoBlock
          }
          ... on WpPage_Components_Component_Content {
            fieldGroupName
            ...ContentBlock
          }
          ... on WpPage_Components_Component_Creditors {
            fieldGroupName
            ...CreditorsBlock
          }
        }
      }
    }
  }
`;
