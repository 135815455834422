import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";
import styled from "styled-components";

import { setPartnerCookie } from "../../helpers/setCookies";

export const Header = ({ data, progress }) => {
  const databaseId = data.databaseId;
  const mainPage = databaseId === 2;
  const registrationPage = databaseId === 68 || databaseId === 70;
  // const successPage = databaseId === 70;
  const pageClass = mainPage
    ? "main-page"
    : registrationPage
    ? "registration"
    : "page";
  const headingText = 'GO<span class="font-weight-bold">prestamo</span>';

  useEffect(() => {
    setPartnerCookie();

    // commented from old version
    /* if (successPage) {
      gtag('config', 'UA-142148414-1', {
        'custom_map': {
          'dimension1': 'id_user'
        }
      });
      gtag('event', 'id_user_dimension', {
        'id_user': dimensionValue // какое dimensionValue тут должно быть?..
      });
    } */
  });

  // TODO: вынести в отдельные файлы
  const isProgress = progress || progress === 0;
  const ProgressBar = () =>
    isProgress ? (
      <div className="progress-line">
        <span className="progress-line__value">{progress}%</span>
      </div>
    ) : null;

  const Heading = () => (
    <h1 dangerouslySetInnerHTML={{ __html: headingText }} />
  );

  const OnlyLink = () => (
    <Link
      to={"/"}
      className="h1"
      dangerouslySetInnerHTML={{ __html: headingText }}
    />
  );

  const HeadingWithLink = () => (
    <h1>
      <Link to={"/"} dangerouslySetInnerHTML={{ __html: headingText }} />
    </h1>
  );

  return (
    <StyledHeaderBlock className={pageClass} id="top" progress={progress}>
      <ProgressBar />
      <Container className="text-center">
        {mainPage ? (
          <Heading />
        ) : registrationPage ? (
          <HeadingWithLink />
        ) : (
          <OnlyLink />
        )}
      </Container>
    </StyledHeaderBlock>
  );
};

const colorText = "#fff";
const colorBg = "#378aff";

const StyledHeaderBlock = styled.header`
  background: ${colorText};
  padding: 10px 0;
  @media (min-width: 768px) {
    padding: 20px 0;
  }
  h1,
  .h1 {
    margin: 0;
    color: ${colorBg};
    font-size: 25px;
    font-weight: 400;
    text-decoration: none; // For links
    @media (min-width: 768px) {
      font-size: 30px;
    }
    a {
      color: inherit;
      text-decoration: inherit;
    }
  }

  // Progress line
  .progress-line {
    position: absolute;
    top: 0;
    width: ${props => props.progress && props.progress}%;
    height: 5px;
    background: #378aff;
    .progress-line__value {
      position: absolute;
      top: 100%;
      right: 0;
      color: #378aff;
      font-size: 12px;
      font-weight: 700;
    }
  }

  // Header states
  &.main-page {
    background: ${colorBg};
    @media (min-width: 992px) {
      background: ${colorText};
    }
    h1,
    .h1 {
      color: ${colorText};
      @media (min-width: 992px) {
        color: ${colorBg};
      }
    }
  }
  &.registration {
    padding: 40px 0 10px;
    @media (min-width: 768px) {
      padding: 40px 0;
    }
  }
`;
