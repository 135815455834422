import { useStaticQuery, graphql } from "gatsby";

export const useFooterRightMenu = () => {
  const { wpMenu } = useStaticQuery(
    graphql`
      query FooterRightMenu {
        wpMenu(databaseId: { eq: 3 }) {
          menuItems {
            nodes {
              databaseId
              path
              label
            }
          }
        }
      }
    `
  );

  return wpMenu.menuItems.nodes;
};
