import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

import { SETTINGS } from "../../../settings";
import { useFooterLeftMenu } from "../../../hooks/use-menu-footer-left";
import { useFooterRightMenu } from "../../../hooks/use-menu-footer-right";

export const FooterMenu = ({ type }) => {
  const LeftMenuItems = useFooterLeftMenu();
  const RightMenuItems = useFooterRightMenu();
  let items = type === "right" ? RightMenuItems : LeftMenuItems;
  return (
    <StyledMenu>
      {items &&
        items.map(item => (
          <li key={item.databaseId}>
            <Link
              to={item.path.replace("wp/", "")}
              dangerouslySetInnerHTML={{ __html: item.label }}
            />
          </li>
        ))}
    </StyledMenu>
  );
};

const StyledMenu = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0 0;
  li + li {
    margin-top: 15px;
  }
`;
